import { escapeSlashes, isCambridgeInternational } from '../../services';

export type BackButtonData = {
  returnUrl: string
  text: string
}
export type BackButtonDataFn = (arg0: {
  roleCAAdmin: boolean,
  params: { [key: string]: string }
}) => BackButtonData | null

const HOME: BackButtonData = {
  returnUrl: '/',
  text: 'Home'
}
export const NULL: BackButtonData = Object.freeze({
  returnUrl: 'NULL',
  text: 'NULL'
})

interface IDictionary {
  [key: string]: BackButtonDataFn | BackButtonData | null
}
export const DICTIONARY: IDictionary = {
  '/aam': HOME,
  '/aam/:qualification': {
    returnUrl: '/aam',
    text: 'OCR Qualifications'
  },
  '/aam/:qualification/:specification': ({ params }) => ({
    returnUrl: `/aam/${params.qualification}`,
    text: `${params.qualification} Specifications`,
  }),
  '/aam/:qualification/:specification/:unitCode': ({ params }) => ({
    returnUrl: `/aam/${params.qualification}/${escapeSlashes(params.specification)}`,
    text: `Unit/Component`,
  }),
  '/centres': () => {
    return isCambridgeInternational() ? NULL : HOME
  },
  '/centres/:id': ({ roleCAAdmin }) => {
    if (isCambridgeInternational()) {
      return roleCAAdmin
        ? {
          returnUrl: '/centres',
          text: 'Home',
        }
        : NULL
    }
    if (roleCAAdmin) {
      return {
        returnUrl: '/centres',
        text: 'Internal dashboard',
      }
    }
    return HOME
  },
  '/centres/:id/syllabus/:syllabusId': ({ params }) => ({
    returnUrl: `/centres/${params.id}`,
    text: 'Dashboard',
  }),
  '/manage/aam': HOME,
  '/centres/:id/manage/aam': HOME,
  '/manage/teachers': HOME,
  '/manage/signoff': HOME,
  '/centres/:id/manage/teachers': HOME,
  '/centres/:id/manage/signoff': HOME,
  '/centres/:id/manage/admins': HOME,
  '/help-and-contact': HOME,

  DEFAULT: NULL
};
