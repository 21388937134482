import { useCallback, useMemo } from 'react'
import useReactRouter from 'use-react-router'
import { isFuture, isPast } from 'date-fns'

import { useConfig } from './use-config'
import { useAuth0 } from '../services'
import { isCAAdmin } from '../services'

export const useExpiryPage = () => {
  const { location } = useReactRouter()
  const { config } = useConfig()
  const { user, currentCentre } = useAuth0()

  const { captureAvailableFrom, captureAvailableTo, aamAvailableTo } = config

  const captureExpiryRoutes: RegExp[] = [
    new RegExp(
      `^\\/centres\\/${currentCentre}(\\/syllabus\\/\\d+){0,1}\\/?$`,
      'i'
    ),
    new RegExp(`^\\/manage\\/teachers\\/?$`, 'i'),
    new RegExp(`^\\/manage\\/signoff\\/?$`, 'i'),
  ]

  const aamExpiryRoutes: RegExp[] = [
    ...captureExpiryRoutes,
    new RegExp(`^\\/manage\\/aam\\/?$`, 'i'),
    new RegExp(`^\\/aam(\\/[\\w,(%20)\\s\\-]+)*\\/?$`, 'i'),
  ]

  const isExpiryRoute = useCallback(
    (routes: RegExp[]): boolean =>
      routes.some((route) => route.test(location.pathname)),
    [location]
  )

  const isCaAdmin: boolean = useMemo(() => !!user && isCAAdmin(user), [user])

  const aamAvailabilityExpired: boolean = useMemo(
    () =>
      isExpiryRoute(aamExpiryRoutes) &&
      !isCaAdmin &&
      !!aamAvailableTo &&
      isPast(aamAvailableTo),
    [aamAvailableTo, isExpiryRoute, isCaAdmin, aamExpiryRoutes]
  )

  const captureAvailabilityClosed: boolean = useMemo(
    () =>
      isExpiryRoute(captureExpiryRoutes) &&
      !isCaAdmin &&
      !!captureAvailableFrom &&
      isFuture(captureAvailableFrom),
    [captureAvailableFrom, isExpiryRoute, isCaAdmin, captureExpiryRoutes]
  )

  const captureAvailabilityExpired: boolean = useMemo(
    () =>
      isExpiryRoute(captureExpiryRoutes) &&
      !isCaAdmin &&
      !!captureAvailableFrom &&
      isPast(captureAvailableFrom) &&
      !!captureAvailableTo &&
      isPast(captureAvailableTo),
    [
      captureAvailableFrom,
      captureAvailableTo,
      isExpiryRoute,
      isCaAdmin,
      captureExpiryRoutes,
    ]
  )

  return [
    aamAvailabilityExpired,
    captureAvailabilityClosed,
    captureAvailabilityExpired,
  ]
}
