import axios, { AxiosResponse } from 'axios'
import React, { FC, useMemo } from 'react'
import './specifications-to-qualification.scss'
import { useAxios } from 'react-hooks-async'
import { useHistory } from 'react-router-dom'
import { Container } from 'reactstrap'
import { Loading } from '../../common/components/loader/loading'
import { FilePathService } from '../../common/services/file-path.service'
import { escapeSlashes } from '../../common/services'
import { SpecificationsDTO } from '../../common'

interface LetterWithSpecifications {
  letter: string
  specifications: string[]
}

interface ISpecificationToQualification {
  qualification: string
}

interface Question {
  questions: string
  answers: string[]
}

export const SpecificationToQualification: FC<ISpecificationToQualification> = ({
  qualification,
}) => {
  const specificationRowHeight = 43
  const paddingBetweenSpecificationForLetter = 40
  const letterBlockHeight = 30

  const history = useHistory()

  const navigateToSpecification = (specification: string) =>
    history.push(`/aam/${qualification}/${escapeSlashes(specification)}`)

  const getQualificationSpecificationsUrl = useMemo(() => {
    return {
      url: `${process.env.REACT_APP_APIDOMAIN}/${FilePathService.encode(
        qualification
      )}/specifications`,
    }
  }, [qualification])

  const getQualificationSpecifications = useAxios<
    AxiosResponse<SpecificationsDTO>
  >(axios, getQualificationSpecificationsUrl)

  const letterWithSpecificationsList: LetterWithSpecifications[] = useMemo(() => {
    const res: LetterWithSpecifications[] = []
    const result = getQualificationSpecifications.result?.data.items

    result?.forEach((specification) => {
      const letter = specification.trim().toLocaleUpperCase().charAt(0)
      const letterWithSpecifications = res.find((el) => el.letter === letter)
      if (letterWithSpecifications) {
        letterWithSpecifications.specifications.push(specification)
        return
      }
      res.push({
        letter,
        specifications: [specification],
      })
    })
    res.sort((first, second) => first.letter.localeCompare(second.letter))
    return res
  }, [getQualificationSpecifications.result])

  const calculateSpecificationsForLetterBlockHeight = (
    letterSpecifications: LetterWithSpecifications
  ) =>
    paddingBetweenSpecificationForLetter +
    letterBlockHeight +
    letterSpecifications.specifications.length * specificationRowHeight

  const fullSpecificationsForLetterBlockHeight = useMemo(
    () =>
      letterWithSpecificationsList.reduce(
        (acc, letterSpecifications) =>
          acc +
          calculateSpecificationsForLetterBlockHeight(letterSpecifications),
        0
      ),
    [letterWithSpecificationsList]
  )

  const getFirstColumnSpecificationsHeight = () => {
    let heightOfFirstColumn = 0
    for (
      let i = 0;
      heightOfFirstColumn < fullSpecificationsForLetterBlockHeight / 2;
      i++
    ) {
      heightOfFirstColumn += calculateSpecificationsForLetterBlockHeight(
        letterWithSpecificationsList[i]
      )
    }
    return heightOfFirstColumn
  }

  const height = useMemo(
    () => ({
      height: getFirstColumnSpecificationsHeight(),
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [letterWithSpecificationsList]
  )

  if (
    getQualificationSpecifications.started &&
    getQualificationSpecifications.pending
  ) {
    return (
      <Container>
        <Loading className="mt-5 d-block mx-auto" />
      </Container>
    )
  }

  return (
    <div>
      <div className="specifications-to-qualification-content container">
        <strong className="header">{`${qualification} Specifications`}</strong>
        <p className="sub-header">
          Click on a specification to view the units/components that have
          additional assessment material within that specification.{' '}
        </p>
        <div style={height} className="body">
          {letterWithSpecificationsList.map((el) => (
            <div key={el.letter} className="specifications-for-letter">
              <strong
                className="specifications-letter"
                data-testid={`specifications-letter-${el.letter}`}
              >
                {el.letter}
              </strong>
              {el.specifications.map((specification, index) => (
                <div
                  key={specification}
                  className="specification-name"
                  data-testid={`spec-${index}`}
                  onClick={() => navigateToSpecification(specification)}
                >
                  {specification}
                </div>
              ))}
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}
